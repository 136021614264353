import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo/seo';
import './styles/index.scss';
import { Container, Row, Col, Stack } from "react-bootstrap";

const mediaKit = ({ data }) => {
  console.log(data)
  return (
    <div className="background-theme">
      <Container className="pt-5 pb-5 internal-pages-common media-kit">
        <h1>{data.allDatoCmsMediaKitPage.nodes[0].slugPage.title}</h1>
        <Stack className="card-long">
          <img alt="card-long-first" src={data.allDatoCmsMediaKitPage.nodes[0].bannerPage.url} />
          <div className='back-image'>
            <h4>{data.allDatoCmsMediaKitPage.nodes[0].titleHero[0].title}</h4>
            <p>{data.allDatoCmsMediaKitPage.nodes[0].titleHero[0].paragraph}</p>
          </div>
        </Stack>
        <Stack className='mt-3'>
          <Row>
            <Col md={6}>
              <img className='d-none-max-767' alt="imageMediaKit" src={data.allDatoCmsMediaKitPage.nodes[0].imageMediaKit.url} />
            </Col>
            <Col md={6}>
            {
              data.allDatoCmsMediaKitPage.nodes[0].titleAndParagraph.map((titleAndParagraph, index) =>
                <div key={index}>
                  <h4>{titleAndParagraph.title}</h4>
                  <p>{titleAndParagraph.paragraph}</p>
                </div>
              )
            }
            </Col>
          </Row>
        </Stack>
      </Container>
    </div>
  )
}

export default mediaKit

export const Head = ({ data }) => (
  <Seo
    title="Media Kit"
    description={data.allDatoCmsMediaKitPage.nodes[0].seo.description}
    image={data.allDatoCmsMediaKitPage.nodes[0].seo.image.url}
  />
)

export const query = graphql`
query MediaKitQuery {
  allDatoCmsMediaKitPage {
    nodes {
      seo {
        description
        title
        image {
          url
        }
      }
      slugPage {
        slug
        title
      }
      bannerPage {
        url
      }
      titleHero {
        title
        paragraph
      }
      imageMediaKit {
        url
      }
      titleAndParagraph {
        title
        paragraph
      }
    }
  }
}
`

